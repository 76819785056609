@import '../../scss/variables';

.footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: auto;
  padding: 15px;
  text-align: center;
  bottom: 0;
  background-color: $color-black;
  color: white;

  .social {
    padding: 15px;

    svg {
      width: 45px;
      height: 45px;
    }

    .email {
      &:hover {
        color: #d93025;
      }
    }

    .facebook {
      &:hover {
        color: #1877f2;
      }
    }

    .instagram {
      &:hover {
        color: #C13584;
      }
    }

    .whatsapp {
      &:hover {
        color: #4ced69;
      }
    }
  }
}