@import '../../scss/variables';
@import url('https://cdn.syncfusion.com/ej2/material.css');

.card {
  width: 33%;
  height: 100%;
  margin: 15px;
  padding-bottom: 10px;
  transition: all .2s ease-in-out !important;

  .card-description {
    border-bottom: 1px solid black;

    .card-link {
      text-align: left;
      padding-bottom: 5px;

      a {
        font-weight: 700;
        color: $color-pink;
        cursor: pointer;

        &:hover {
          color: $color-pink;
          opacity: .5;
        }
      }
    }
  }

  .card-days {
    color: $color-black;

    &:hover {
      color: $color-pink;
    }
  }

  .card-price {
    font-size: 28px;
    padding-top: 20px;
  }

  .hide {
    display: none;
  }

  &:hover {
    transform: scale(1.1);
  }

  .btn-container {
    display: inline-flex;
    justify-items: center;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    margin: 15px;
  }
}

.paper-calendar {
  text-align: center;
  border: 4px solid $color-orange;
  border-radius: 15px;
  padding: 2px 4px 3px;
  background: white;
  overflow-y:auto;
  min-width: 300px;

  .modal-label {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    padding-top: 10px;

    span {
      width: 20px;
      height: 10px;
      background: $color-pink;
    }

    label {
      padding-left: 10px;
    }
  }

  .e-selected span.e-day {
    background: $color-pink !important;
    color: white !important;
  }

  .e-focused-date span.e-day {
    background: $color-pink !important;
    color: white !important;
  }

  .e-disabled span.e-day {
    background: white !important;
    color: black!important;
  }

  span.e-day {
    border-radius: 20px !important;
    background: $color-pink !important;
    color: white !important;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  #modal-description-calendar {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      color: $color-black !important;
    }
  }
}

.paper-label {
  display: flex;
  align-items: center;
  width: 100%;
  height: 120px;

  .paper-date {
    width: 35%;
    height: 100px;
    border: solid 1px #ccc;
    background-color: #efe0f7;

    label {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: x-large;
      height: 100%;
    }
  }

  .paper-description {
    width: 65%;
    height: 100px;
    border-top: solid 1px #ccc;
    border-bottom: solid 1px #ccc;
    border-right: solid 1px #ccc;
    background-color: #f3f3f3;

    .paper-buy {
      padding-top: 5px;

      div {
        margin: 5px;
      }

      .paper-click {
        cursor: pointer;
        color: black;

        :hover {
          color: $color-pink;
        }
      }

      .paper-left {
        color: $color-purple;
      }
    }
  }
}