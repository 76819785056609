
$size-header: 210px;
$height-header: $size-header - 90px;

// **** COLORS **** //
$color-black: #1f2729;
$color-orange: #ffcc65;
$color-orange-light: #feffe8;
$color-pink: #c02381;
$color-blue: #d1f8ff;
$color-purple: #3c1d5d;

$color-grey-dark: #44494a;
$color-grey-light: #cccccc;
$color-green: #2ab32a;
$color-green-dark: #3a653a;
$color-red: #ef4949;
$color-red-dark: #b14242;
$color-orange-dark:#9e7b34

