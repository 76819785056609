@import '../../scss/variables';

.about {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;
  background-color: $color-blue;

  .about-section {
    background-color: white;
    padding: 20px;
    margin-bottom: 10px;

    .about-founder {
      display: inline-flex;
      
      .bartira-img {
        width: 300px;
        height: 200px;
        margin-top: 15px;
      }

      .description {
        padding: 0 20px;
        text-align: left;
      }
    }

    .about-video {
      width: 75%;
      height: 29.6rem;

      &:focus {
        outline: none;
      }
    }
  }

  @media only screen and (min-width: 800px) {
    .about-section {
      padding: 20px 10rem 20px 10rem;
    }
  }

  @media only screen and (max-width: 600px) {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;

    .about-section {

      .about-founder {
        display: block;
  
        .bartira-img {
          width: 100%;
          height: 100%;
        }
      }

      .about-video {
        width: 100%;
        height: 15rem;
      }
    }
  }
}