@import '../../scss/variables';

.gallery {
  padding-top: 50px !important;
  min-height: 30rem;
  background-color: $color-purple;
  color: white;

  h1 {
    border-bottom: solid 1px white;
  }

  .gallery-carousel {
    display: flex;
    justify-content: center;

    .carousel-title {
      padding-top: 30px;
    }

    img {
      width: 600px;
      height: 500px;
      object-fit: contain;
      background-color: $color-purple;
    }
  }

  ._3WRGR {
    fill: white !important;
  }

  ._2c50p,
  ._Pfcmb,
  ._-LJ2W,
  ._1eGao {
    background-color: $color-purple;
  }

  .__JnHV {
    background: none !important; 
  }


  @media only screen and (max-width: 600px) {
    padding-top: 5px !important;
    padding-bottom: 0rem !important;

    .gallery-carousel {
      img {
        height: 600px !important;
        width: 100% !important;
        object-fit: cover !important;
      }
    }
  }
}