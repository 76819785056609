@import '../../../scss/variables';

.course-content {
  margin-top: 60px;
  background-color: $color-black;

  .video {
    width: 100%;
    height: 80vh;

    &:focus {
      outline: none;
    }
  }

  .links {
    padding: 10px;
    background-color: white;

    a {
      color: blue;
    }
  }
}