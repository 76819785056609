@import '../../scss/variables';

.header {
  height: 120px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  background-color: $color-pink;
  z-index: 1111;

  .logo-container {
    img {
      height: $size-header;
      width: 220px;
      background-color: $color-pink;
      border-radius: 50%;
    }
  }

  .options {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 80%;
    height: 90%;
    border-bottom: 1px solid white;
    padding: 20px 20px 0px 0px;

    .option {
      font-size: 16px;
      font-weight: 500;
      padding: 10px 15px;
      margin: 5px;
      cursor: pointer;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  .line-bottom {
    border-bottom: solid 5px $color-purple;
  }

  @media only screen and (max-width: 600px)  {
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    justify-content: center;
    position: relative;
  }
}