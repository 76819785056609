@import './scss/variables';

* {
  box-sizing: border-box;;
}

body {
  font-family: 'Livvic', sans-serif;
  padding-top: $height-header;
  font-size: 14px;

  @media only screen and (max-width: 600px) {
    padding-top: 0px;
  }
}

a {
  text-decoration: none;
  color: white;
}

.container {
  padding: 1.2rem 3.8rem;

  @media only screen and (max-width: 600px) {
    padding: 0px;
  }
}

.txt-center {
  text-align: center;
}

.txt-border {
  border-bottom: 1px solid $color-black;
  padding-bottom: 5px;
}

.float-r{
  float: right;
}

.float-l{
  float: left;
}

.btn-orange {
  width: 180px;
  height: 50px;
  font-size: 16px !important;
  font-weight: 500 !important;
  border: solid 3px $color-orange !important;
  color: $color-orange !important;

  a {
    color: $color-orange !important;
  }

  &:hover {
    border: solid 3px $color-purple !important;
    color: $color-purple !important;
    background-color: $color-orange !important;

    a {
      color: $color-purple !important;
    }
  }
}

.btn-purple {
  width: 180px;
  height: 50px;
  font-size: 16px !important;
  font-weight: 500 !important;
  border: solid 3px $color-purple !important;
  color: $color-purple !important;

  a {
    color: $color-purple !important;
  }

  &:hover {
    border: solid 3px $color-orange !important;
    color: $color-orange !important;
    background-color: $color-purple !important;

    a {
      color: $color-orange !important;
    }
  }
}

// CSS HACKS
.MuiInput-underline:after {
  border-bottom: 2px solid $color-pink !important;
}

.MuiFormLabel-root.Mui-focused {
  color: $color-pink !important;
}

.MuiBadge-colorPrimary {
  color: #fff;
  background-color: $color-purple !important;
}

.MuiTypography-subtitle2 {
  cursor: pointer !important;
}

textarea {
  font-family: 'Livvic', sans-serif !important;
  font-size: 16px !important;

  &:focus {
    outline: $color-pink auto 1px !important;
  }
}

.leaflet-container {
  width: 60vw;
  height: 60vh;
  margin: 0px 15% 0px 15%;

  @media only screen and (max-width: 600px) {
    width: 100%;
    margin: 0px;
  }
}