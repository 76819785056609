@import '../../scss/variables';

.cart {
  padding-top: 120px !important;
  min-height: 30rem;

  .cart-container {
    width: 100%;
    padding: 20px;
    text-align: center;
    border: 1px solid #ccc;

    .voucher {
      padding-top: 20px;

      .input-voucher {
        input {
          text-transform: uppercase;
        }
      }

      .btn-voucher {
        background-color: $color-orange;
        border: solid 1px $color-black;
        margin-top: 12px;
        margin-left: 5px;

        &:hover {
          border: solid 1px $color-orange;
          color: $color-orange;
          background-color: $color-black;
        }
      }
    }
  }

  @media only screen and (max-width: 600px) {
    padding-top: 50px !important;
    padding: 20px !important;
  }
}