.checkout-item {
  display: flex;
  align-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid black;
  margin: 10px;
  width: 100%;

  .name {
    font-weight: 700;
    padding: 20px;
    width: 80%;
    text-align: left;

    @media only screen and (max-width: 600px) {
      padding: 0px !important;
      width: 100%;
    }
  }

  .remove-button {
    cursor: pointer;
    color: red;
    padding: 20px;
  }

  .price {
    font-size: 30px;
    padding: 10px;
  }
}