@import '../../scss/variables';

.item-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  .paper {
    border: 4px solid $color-orange;
    border-radius: 15px;
    padding: 2px 4px 3px;
    background: white;
    width: 25%;
    text-align: center;

    .item-flag {
      margin: 0px 15px 0px 15px;
      cursor: pointer;

      &:hover {
        filter: brightness(50%);
      }
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }

    @media only screen and (max-width: 600px) {
      width: 70%;
    }
  }

  .modal-label {
    display: flex;

    div {
      width: 50%;

      &:first-child {
        padding-right: 35px;
        text-align: right;
      }

      &:last-child {
        padding-left: 30px;
        text-align: left;
      }
    }
  }
}