@import '../../../scss/variables';

.form-course-content {
  background-color: white;
  padding: 20px;

  .form-question {
    padding: 10px;

    label {
      font-size: 18px;
    }

    .wrong {
      border: 2px solid $color-red;
    }

    .correct {
      border: 2px solid $color-green;
    }
  }

  .form-question-input {
    label {
      font-size: 16px;
    }
  }

  .btns {
    text-align: right;
  }

  .btn-save {
    background-color: $color-green;
    color: white;
    margin: 10px;

    &:hover {
      background-color: $color-green-dark;
    }
  }

  .btn-go {
    background-color: $color-orange;
    color: white;
    margin: 10px;

    &:hover {
      background-color: $color-orange-dark;
    }
  }
}