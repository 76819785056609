@import '../../scss/variables';

.reports {
  padding-top: 100px !important;
  min-height: 30rem;

  .report {
    padding: 15px;
    border-bottom: 1px solid #ccc;
    margin: 10px;
    width: 100%;

    a {
      color: black !important;
    }

    .download {
      text-align: right;
    }

    .filter {
      display: flex;

      .form-input {
        padding: 5px;
      }
    }

    .table {
      min-width: 700px;

      thead {
        th {
          background-color: $color-grey-dark;
          color: white;
        }
      }

      tbody {
        font-size: 14px;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    padding-top: 50px !important;
    padding: 20px !important;

    .course {
      width: 100%;
    }
  }
}