@import '../../scss/variables';

.sidemenu {
  width: 18rem;
  margin-top: 60px;
  
  a {
    color: $color-black;
    display: block;
    padding: 18px 15px;
    text-decoration: none;
  }

  .menu-title {   
    font-size: 16px;
    font-weight: 500;
    border-bottom: $color-grey-light solid 1px;

    &:hover {
      background-color: $color-grey-light;
    }
  }

  .menu-item { 
    &:hover {
      background-color: $color-grey-light;
    }
  }

  .hide {
    display: none;
  }

  .watched {
    background-color: $color-blue;
  }

  @media only screen and (max-width: 600px)  {
    margin-top: 6rem;
  }
}

.header-course {
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: start;
  top: 0;
  position: fixed;
  background-color: $color-grey-dark;
  z-index: 1111;
  color: white;

  h2 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .toggle-menu {
    text-decoration: none;
    color: white;
    padding: 8px;
    font-size: 28px;
  }

  @media only screen and (max-width: 600px)  {
    flex-wrap: wrap;
    width: 100%;
    height: 6rem;

    h2,h4 {
      margin: 6px;
    }
    
    h2 {
      padding-left: 10%;
    }

    h4 {
      padding-left: 22%;
    }
  }
}