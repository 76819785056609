@import '../../scss/variables';

.burger-menu {
  position: absolute;
  top: 10px;
  left: 20px;
  z-index: 20;
  display: none;
  color: white;

  svg {
    width: 3rem;
    height: 3rem;
  }

  @media (max-width: 768px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column;
  }
}

.nav-right {
  display: flex;
  flex-flow: row nowrap;
  display: none;
  font-size: 18px;
  font-weight: 500;

  a {
    padding: 18px 10px;
    border-bottom: 1px solid white;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-flow: column nowrap;
    background-color: $color-pink;
    width: 300px;
  }
}

.hide {
  display: none;
}