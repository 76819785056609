@import '../../scss/variables';

.dashboard {
  padding-top: 120px !important;
  min-height: 30rem;

  .course {
    display: flex;
    align-content: space-between;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #ccc;
    margin: 10px;
    width: 70%;

    .name {
      font-weight: 700;
      width: 45%;
      text-align: left;
    }

    .deadline {
      width: 40%;
      text-align: left;

      .line {
        padding: 5px;
      }
    }
  }

  .user {
    display: flex;
    align-content: space-between;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #ccc;
    margin: 10px;
    width: 70%;

    h1 {
      width: 50%;
      text-align: left;
    }

    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }

  .add-button {
    color: $color-pink;
    text-transform: uppercase;
  }

  .edit-button {
    width: 7rem;
    margin: 5px;
    padding: 0 8px 8px 8px;
    border: 1px solid;
    color: black;
    text-transform: uppercase;
    text-align: center;

    .MuiSvgIcon-root {
      padding-top: 5px;
    }

    &:hover {
      background-color: #ccc;
    }

    @media only screen and (max-width: 600px) {
      width: 8rem;
    }
  }

  .delete-button {
    width: 7rem;
    margin: 5px;
    padding: 0 8px 8px 8px;
    border: 1px solid;
    color: $color-red;
    text-transform: uppercase;
    text-align: center;

    .MuiSvgIcon-root {
      padding-top: 5px;
    }

    &:hover {
      background-color: #f5d4d4;
    }

    @media only screen and (max-width: 600px) {
      width: 10rem;
    }
  }

  @media only screen and (max-width: 600px) {
    padding-top: 50px !important;
    padding: 20px !important;

    .course {
      width: 100%;
    }
  }
}