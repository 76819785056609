@import '../../scss/variables';

.congratulations {
  padding-top: 120px !important;
  min-height: 30rem;
  text-align: center !important;

  a {
    padding: 10px;
  }

  @media only screen and (max-width: 600px) {
    padding-top: 50px !important;
    padding: 20px !important;
  }
}