.contact {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;

  section:first-child {
    margin-bottom: 5rem;
  }
  
  .form-container {
    width: 100%;
    padding: 0px 25% 0px 25%;
  
    .form-input {
      width: 100%;
    }
  
    .textarea {
      height: 100px !important;
    }
  
  }

  @media only screen and (max-width: 600px) {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;

    .form-container {
      width: inherit;
      padding: inherit;
      margin: 15px;
    }
  }
}