@import '../../scss/variables';

.services {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;
  background-color: $color-purple;
  color: white;

  .services-cards {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
  }

  .line-bottom {
    border-bottom: 1px solid white;
    padding-bottom: 20px;
  }

  @media only screen and (max-width: 600px) {
    padding-top: 5px !important;
    padding-bottom: 0rem !important;
  }
}